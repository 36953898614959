//colours
//common
$brand-grey: #E0E0E0;  
$brand-dark: #121212;

//brand
$brand-primary: darken($brand-grey, 20%);
$brand-secondary: $brand-grey;
$brand-placeholder-color:$brand-grey;

//underscores styles
$color__background-body: #fff; 
$color__background-screen: #f1f1f1;
$color__background-hr: $brand-grey;
$color__background-button: $brand-primary;
$color__background-pre: #eee;
$color__background-ins: #fff9c0; 
 
$color__text-screen: #21759b;
$color__text-input: $brand-primary;
$color__text-input-focus: $brand-primary;
$color__link: $brand-primary;
$color__link-visited: $brand-primary;
$color__link-hover: $brand-secondary;
$color__text-main: $brand-dark; 

$color__border-button: $brand-primary;
$color__border-button-hover: $brand-secondary;
$color__border-button-focus: $brand-secondary;
$color__border-input: $brand-primary;
$color__border-abbr: $brand-secondary;


