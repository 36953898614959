/*--------------------------------------------------------------
## Site vars
--------------------------------------------------------------*/
/*--------------------------------------------------------------
## Include Media
--------------------------------------------------------------*/
/*--------------------------------------------------------------
## Links
--------------------------------------------------------------*/
/*--------------------------------------------------------------
## Menus
--------------------------------------------------------------*/
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
button.hamburger {
  padding: 1px 5px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 2px solid #adadad;
  margin: 0;
  overflow: visible;
  border-radius: 3px;
}

button.hamburger:hover, button.hamburger:focus {
  opacity: 1;
  background-color: transparent;
}

button.hamburger:hover:hover, button.hamburger:hover.is-active, button.hamburger:focus:hover, button.hamburger:focus.is-active {
  background-color: transparent;
}

button.hamburger:hover .hamburger-inner, button.hamburger:hover .hamburger-inner::before, button.hamburger:hover .hamburger-inner::after, button.hamburger:focus .hamburger-inner, button.hamburger:focus .hamburger-inner::before, button.hamburger:focus .hamburger-inner::after {
  background-color: #adadad;
}

button.hamburger.is-active .hamburger-inner::before, button.hamburger.is-active .hamburger-inner::after {
  background-color: #adadad;
}

.hamburger-box {
  width: 30px;
  height: 14px;
  display: inline-block;
  position: relative;
  top: 2px;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1px;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 30px;
  height: 2px;
  background-color: #adadad;
  border-radius: 3px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -6px;
}

.hamburger-inner::after {
  bottom: -6px;
}

/*
   * Collapse
   */
.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.15s;
  transition-delay: 0.15s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse .hamburger-inner::after {
  top: -12px;
  transition: top 0.3s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}

.hamburger--collapse .hamburger-inner::before {
  transition: top 0.12s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -6px, 0) rotate(-45deg);
  transition-delay: 0.32s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--collapse.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.27s linear;
}

.hamburger--collapse.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-90deg);
  transition: top 0.12s 0.18s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s 0.42s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.main-navigation {
  display: block;
  transition: .5s linear all;
}

@media (max-width: 768px) {
  .main-navigation {
    text-align: right;
  }
}

.main-navigation button {
  border: none;
  line-height: 2;
}

.main-navigation ul {
  list-style: none;
  margin: 0;
  padding-left: 0;
}

.main-navigation ul ul {
  position: absolute;
  z-index: 99999;
  background-color: #FFF;
  min-width: 200px;
}

@media (max-width: 768px) {
  .main-navigation ul ul {
    position: relative;
    float: none;
    left: auto;
    top: auto;
  }
}

@media (max-width: 768px) {
  .main-navigation ul ul ul {
    left: initial;
  }
}

.main-navigation li {
  position: relative;
  text-align: left;
  display: inline-block;
  vertical-align: top;
}

@media (max-width: 768px) {
  .main-navigation li {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .main-navigation li > a {
    width: calc(100% - 40px);
  }
}

.main-navigation a {
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  padding: 5px;
}

.main-navigation .sub-menu {
  width: 200px;
}

@media (max-width: 768px) {
  .main-navigation .sub-menu {
    width: 100%;
  }
}

.main-navigation .sub-menu li {
  width: 100%;
}

.main-navigation ul.sub-menu,
.main-navigation ul.children {
  display: none;
}

.main-navigation ul.sub-menu.toggled-on,
.main-navigation ul.children.toggled-on {
  display: block;
}

.main-navigation .dropdown-toggle {
  background-color: transparent;
  width: 100%;
  padding: 0;
  text-align: left;
}

.main-navigation .dropdown-toggle a {
  padding: 10px;
}

.site-main .comment-navigation, .site-main
.posts-navigation, .site-main
.post-navigation {
  margin: 0 0 1.5em;
  overflow: hidden;
}

.comment-navigation .nav-previous,
.posts-navigation .nav-previous,
.post-navigation .nav-previous {
  float: left;
  width: 50%;
}

.comment-navigation .nav-next,
.posts-navigation .nav-next,
.post-navigation .nav-next {
  float: right;
  text-align: right;
  width: 50%;
}

@media (min-width: 769px) {
  #mobile-menu {
    display: none;
  }
  .main-navigation ul {
    display: block;
  }
}

.menu-open {
  height: 100%;
  overflow: hidden;
  position: fixed;
}

button#mobile-menu {
  position: relative;
  z-index: 101;
  margin: 25px 0;
  float: left;
  clear: left;
  border: 2px solid #adadad;
}

.toggled {
  transition: .5s linear all;
}

#access {
  float: none;
  left: 15px;
  width: calc(100% - 30px);
}

@media (max-width: 768px) {
  #access {
    width: 100%;
    left: 0;
  }
}

@media (max-width: 768px) {
  #top-menu {
    height: auto;
    float: none;
  }
  #branding a.logo img {
    width: 300px;
    margin: 20px 0 10px;
  }
  a.logo {
    float: none;
    height: auto;
    display: block;
    margin: 0;
    width: 300px;
  }
  .menu-top-container {
    float: none;
    position: relative;
    left: auto;
    right: auto;
    margin: 0 auto;
  }
  .menu-top-container ul {
    margin: 0;
    padding: 0;
  }
  #branding #searchform {
    position: relative;
    margin: -15px 0 20px;
    float: right;
  }
  /*    Menu*/
  .menu-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 250px;
    height: 100vh;
    transition: .5s ease all;
    transform: translate(-250px, 0);
    z-index: 100;
    background-color: #FFF;
    text-align: center;
    overflow: auto;
  }
  #access div {
    margin: 0;
  }
  #access {
    float: none;
    position: relative;
    height: auto;
    background: none;
    top: 0;
  }
  #access ul ul {
    width: 100%;
    border: none;
    box-shadow: none;
    background-color: #d2d2d2;
  }
  #access ul ul li {
    width: 100%;
    border: none;
  }
  #access ul ul ul {
    display: none;
  }
  #access ul li:hover > ul {
    display: none;
  }
  #access ul li > ul.toggled-on {
    display: block;
    position: relative;
    float: none;
    top: auto;
    left: auto;
    height: auto;
  }
  #access li {
    float: none;
    position: relative;
    background: url(images/navSep.gif) no-repeat left top;
    height: auto;
    display: block;
    margin: 0;
    cursor: pointer;
  }
  #menu-main li a span.sub {
    width: 80%;
    display: inline-block;
    margin: 5px 10px 5px 0;
  }
  #menu-main li a span.sub {
    position: relative;
  }
  .main-navigation .dropdown-toggle {
    margin: 0;
  }
  #access #menu-main > li:hover,
  #access #menu-main > li:focus {
    border: none;
    background-color: #f3f3f3;
  }
  .dropdown-toggle.toggled-on {
    background-color: #f9f9f9;
  }
  #access ul#menu-main ul ul.sub-menu > li.menu-item-has-children > button {
    /*        background: none;*/
  }
  #access ul ul a {
    border-top: 1px solid #717171;
    background-color: #e2e2e2;
  }
  body #access li.current-menu-item a {
    font-weight: bold !important;
  }
  #access ul ul ul ul {
    background-color: #bfbfbf;
  }
  #access ul ul li li {
    background: none;
  }
  #access ul ul li {
    background: none;
  }
  #access ul#menu-main ul.sub-menu li ul.sub-menu > li a, #access ul#menu-main ul.sub-menu li.menu-sub-style a {
    background-color: #cecece;
  }
  #access ul#menu-main ul.sub-menu li ul.sub-menu > li.menu-item-has-children > button a {
    background-color: #e2e2e2;
    text-transform: uppercase;
    font-weight: bold;
  }
  #access ul#menu-main ul.sub-menu li ul.sub-menu > li ul.sub-menu li a {
    background: #FFF;
  }
  div#main:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    visibility: hidden;
    transition: .3s linear all;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000;
  }
  .push div#main:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    transition: .3s linear all;
    z-index: 100;
    visibility: visible;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000;
  }
  .arrow-down {
    border: 1px solid #adadad;
    border-width: 0 0 1px 1px;
    width: 10px;
    height: 10px;
    margin: 5px;
    display: inline-block;
    transform: rotate(-135deg);
    position: absolute;
    right: 20px;
    text-align: 0;
    top: 13px;
  }
  .toggled-on > a .arrow-down {
    transform: rotate(-45deg);
  }
}

.toggled .menu-wrapper {
  left: 0;
  width: 250px;
  -webkit-transition: .5s ease all;
  transition: .5s ease all;
  padding: 5vh 0 15vh 0;
}

.toggled .menu-wrapper .overlay-content {
  height: 100%;
}

.site {
  position: relative;
  top: 0;
  left: 0;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-transition: .7s ease transform;
  transform: translate(0, 0);
  transition: .7s ease transform;
  min-height: 100vh;
}

.push {
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-transform: translate(250px, 0);
  transform: translate(250px, 0);
  -webkit-transition: .5s ease transform;
  transition: .5s ease transform;
}

@media (max-width: 768px) {
  .main-navigation {
    text-align: left;
  }
  body {
    overflow-x: hidden;
  }
}

@media (max-width: 768px) {
  .menu-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 250px;
    height: 100vh;
    overflow: auto;
    transition: .5s ease all;
    transform: translate(-250px, 0);
    z-index: 100;
    background-color: #FFF;
    text-align: center;
    padding: 5vh 0 15vh 0;
  }
}

.toggled .menu-wrapper {
  left: 0;
  width: 250px;
  transition: .5s ease all;
}

.toggled .menu-wrapper .overlay-content {
  height: 100%;
}

@media (max-width: 768px) {
  .nav-menu > li {
    transform: translate3d(0, 1500px, 0);
    transition: transform 0s 0.4s;
    transition-timing-function: cubic-bezier(0.7, 0.3, 0.5, 1);
  }
  .nav-menu > li.slideup {
    transform: translate3d(0, 0, 0);
    transition: transform 0.8s;
    transition-timing-function: cubic-bezier(0.7, 0.3, 0.5, 1);
  }
}

.site {
  position: relative;
  top: 0;
  left: 0;
  transition: .5s ease all;
  min-height: 100vh;
  transform: translate(0, 0) translate3d(0, 0, 0);
}

.push {
  transform: translate(250px, 0) translate3d(0, 0, 0);
  transition: .5s ease all;
}
