.main-navigation {
	display: block;
//	text-align: center;
	transition:.5s linear all;
    
    @include media("<=tablet") {
        text-align: right;
    }

    button {
        border: none;
        line-height: 2; 
    }
	ul {
		
		list-style: none; 
		margin: 0;
		padding-left: 0;

		ul {
//			box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
//			float: left;
			position: absolute;
//			top: 1.5em;
//			left: -999em;
			z-index: 99999;
            background-color: #FFF;
//            padding-left: 10%;
            min-width: 200px;
            
            @include media("<=tablet") {
                position: relative;
                float: none;
                left: auto;
                top: auto;
            }

			ul {
//				left: -999em;
//				top: 0;
                
                @include media("<=tablet") {
                    left: initial;
                }
			} 

			li {
				&:hover > ul,
				&.focus > ul {
//					left: 100%;
				}
			}

			a {
//				width: 200px;
			}

			:hover > a,
			.focus > a {
			}

			a:hover,
			a.focus {
			}
		}

		li:hover > ul,
		li.focus > ul {
//			left: auto; 
		}
	}
	
	li {
		position: relative;
        text-align: left;
        display: inline-block;
        vertical-align: top;
//        padding: 5px;
        
        @include media("<=tablet") {
            width: 100%;
        }    
        
        > a {
            @include media("<=tablet") {
                width: calc(100% - 40px);
            }
        }
        
		&:hover > a,
		&.focus > a {
		}
	}
    
	a {
		display: inline-block;
        vertical-align: middle;
		text-decoration: none;
//        width: 100%;
        padding: 5px;

	}

	.current_page_item > a,
	.current-menu-item > a,
	.current_page_ancestor > a,
	.current-menu-ancestor > a {
	}
    
    .sub-menu {
        width: 200px;
        
        @include media("<=tablet") {
            width: 100%;
        }
        
        li {
            width: 100%;
        }
    }
    
    ul.sub-menu,
    ul.children {
        display: none;
         
        &.toggled-on {
            display: block;
        }
    }
    
    .dropdown-toggle {
//        margin: 5px;
        background-color: transparent;
        width: 100%;
        padding: 0;
        text-align: left;
//        float: right;
        a {
            padding: 10px;
        }
    } 
    
}

.comment-navigation,
.posts-navigation,
.post-navigation {

	.site-main & {
		margin: 0 0 1.5em;
		overflow: hidden;
	}

	.nav-previous {
		float: left;
		width: 50%;
	}

	.nav-next {
		float: right;
		text-align: right;
		width: 50%;
	}
}

//Mobile menu global styles
// ==================================================


//media query to hide/show the mobile menu 
@include media(">menu") {
    #mobile-menu {
		display: none;
	}
	.main-navigation ul {
		display: block;
	}
} 

//applied to body and html tags
.menu-open {
	height: 100%;
	overflow: hidden;
    position: fixed;
}

button#mobile-menu {
	position: relative;
	z-index: 101;
    margin: 25px 0;
    float: left;
    clear: left;
    border: 2px solid $brand-primary;
}
.toggled {
	transition:.5s linear all;
}

#access {
    float: none;
    left: 15px;
    width: calc(100% - 30px);
    
    @include media("<=tablet") {
        width: 100%;
        left: 0;
    }    
}

//Custom styles
@media (max-width: 768px) {
    #top-menu {
        height: auto;
        float: none;
    }
    #branding a.logo img {
        width: 300px;
        margin: 20px 0 10px
    }
    a.logo {
        float: none;
        height: auto;
        display: block;
        margin: 0;
        width: 300px;
    }
    .menu-top-container {
        float: none;
        position: relative;
        left: auto;
        right: auto;
        margin: 0 auto;
        
        ul {
            margin: 0;
            padding: 0;
        }
    }
    #branding #searchform {
        position: relative;
        margin: -15px 0 20px;
        float: right;
//        background: none;
    }
//    input#s,
//    input#s:focus {
//        background-color: #FFF;
//    }
/*    Menu*/
    .menu-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        width: 250px;
        height: 100vh;
        transition: .5s ease all;
        transform: translate(-250px, 0);
        z-index: 100;
        background-color: #FFF;
        text-align: center;
        overflow: auto;
    }
    #access div {
        margin: 0;
    }
    #access {
        float: none;
        position: relative;
        height: auto;
        background: none;
        top: 0;
        
    }
    #access ul ul {
        width: 100%;
        border: none;
        box-shadow: none;
        background-color: #d2d2d2;
    }
    #access ul ul li {
        width: 100%;
        border: none;
    }
    #access ul ul ul {
        display: none;
    }
    #access ul li:hover > ul {
        display: none;
    }
    #access ul li > ul.toggled-on {
        display: block;
        position: relative;
        float: none;
        top: auto;
        left: auto;
        height: auto;
    }
    #access li {
        float: none;
        position: relative;
        background: url(images/navSep.gif) no-repeat left top;
        height: auto;
        display: block;
        margin: 0;
        cursor: pointer;
    }
    #menu-main li a span.sub {
        width: 80%;
        display: inline-block;
        margin: 5px 10px 5px 0;
    }
    #menu-main li a span.sub {
        position: relative;
    }
    .main-navigation .dropdown-toggle {
        margin: 0;
    }
    #access #menu-main>li:hover,
    #access #menu-main>li:focus {
        border: none;
        background-color: #f3f3f3;
    }
    .dropdown-toggle.toggled-on {
        background-color: #f9f9f9;
    }
    #access ul#menu-main ul ul.sub-menu > li.menu-item-has-children > button {
/*        background: none;*/
    }
    #access ul ul a {
        border-top: 1px solid #717171;
        background-color: #e2e2e2;
    }
    body #access li.current-menu-item a {
        font-weight: bold !important;
    }
    #access ul ul ul ul {
        background-color: #bfbfbf;
    }
    #access ul ul li li {
        background: none;
    }
    #access ul ul li {
        background: none;
    }
    #access ul#menu-main ul.sub-menu li ul.sub-menu > li a, #access ul#menu-main ul.sub-menu li.menu-sub-style a {
        background-color: #cecece;
    }
    #access ul#menu-main ul.sub-menu li ul.sub-menu > li.menu-item-has-children > button a {
        background-color: #e2e2e2;
        text-transform: uppercase;
        font-weight: bold;
    }
    #access ul#menu-main ul.sub-menu li ul.sub-menu > li ul.sub-menu li a {
        background: #FFF;
    }
    div#main:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0);
        visibility: hidden;
        transition: .3s linear all;
        transform: translate3d(0,0,0);
        backface-visibility: hidden;
        perspective: 1000;
    }
    .push div#main:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
        transition: .3s linear all;
        z-index: 100;
        visibility: visible;
        transform: translate3d(0,0,0);
        backface-visibility: hidden;
        perspective: 1000;
    }
    .arrow-down {
        border: 1px solid $brand-primary;
        border-width: 0 0 1px 1px;
        width: 10px;
        height: 10px;
        margin: 5px;
        display: inline-block;
        transform: rotate(-135deg);
        position: absolute;
        right: 20px;
        text-align: 0;
        top: 13px;
    }
    .toggled-on > a .arrow-down {
        transform: rotate(-45deg);
    }
}

.toggled .menu-wrapper {
    left: 0;
    width: 250px;
    -webkit-transition: .5s ease all;
    transition: .5s ease all;
    padding: 5vh 0 15vh 0;
}

.toggled .menu-wrapper .overlay-content {
    height: 100%;
}

.site {
    position: relative;
    top: 0;
    left: 0;
    -webkit-transform: translate3d(0,0,0);
    -webkit-transition: .7s ease transform;
    transform: translate(0, 0);
    transition: .7s ease transform;
    min-height: 100vh;
}

.push {
    -webkit-transform: translate3d(0,0,0);
    -webkit-transform: translate(250px, 0);
    transform: translate(250px, 0);
    -webkit-transition: .5s ease transform;
    transition: .5s ease transform;
}

// ======================================================================================

// Mobile menu types (Remove or comment out what you don’t need)
// TO USE:
// 1. Add the name as a class to the menu wrapper in header.php
//    eg. <div class="menu-wrapper slideup">
// 2. Make sure the corresponding sass file is being imported
// 3. Change styles
// Sidenote: Everything inside the menu wrapper will be contained within the mobile menu

// ======================================================================================

//@import "menus/overlay";
//@import "menus/slidedown";
//@import "menus/slideleft"; 
//@import "menus/slideright";  
//@import "menus/dropdown"; 
//@import "menus/pushleft"; //no need to add .push to .menu-wrapper -- make sure to js/menus/push.js is enqueued
@import "menus/pushright";  //no need to add .push to .menu-wrapper -- make sure to js/menus/push.js is enqueued