$menu-width: 250px;


@include media("<=tablet") {
    
    .main-navigation {
        text-align: left;
    }
    
    body {
        overflow-x: hidden;
    }
}

.menu-wrapper {
	
	@include media("<=tablet") {
		position: fixed;
		top:0;
		left:0;
		width: $menu-width;
		height:100vh;
        overflow: auto;
		transition:.5s ease all;
        transform: translate(-#{$menu-width},0);
		z-index: 100;
        background-color: #FFF;
        text-align: center;
        padding: 5vh 0 15vh 0; 
	}
    
    .toggled & {
        left:0;
        width: $menu-width; 
        transition:.5s ease all;
//        position: fixed;
        .overlay-content {
            height: 100%;
        }
    }
}

//list animations
@include media("<=tablet") {
    
//    $i: 8; //number of menu items
//    @for $i from 1 through $i {
//        .main-navigation li:nth-child(#{$i}) { 
//            transform: translate3d(0,(0 + ($i * 500)+px,0));
//
//        }
//    }
    
    .nav-menu > li {
//        visibility: hidden;
//        opacity: 0;
        transform: translate3d(0,1500px,0);
        transition: transform 0s 0.4s;
        transition-timing-function: cubic-bezier(0.7,0.3,0.5,1);
        
//        &.fadein {
//            transform: translate3d(0,0,0);
//            transition: transform 0.8s;
//            transition-timing-function: cubic-bezier(0.7,0.3,0.5,1);
//            visibility: visible;
//            opacity: 1;
//            transition: .5s ease all;
//        } 
        
        &.slideup {
            transform: translate3d(0,0,0);
            transition: transform 0.8s;
            transition-timing-function: cubic-bezier(0.7,0.3,0.5,1);
//            visibility: visible;
//            opacity: 1;
        }
    }
    
}

//page styles

.site {
	position: relative;
	top:0;
	left:0;
	transition:.5s ease all;
	min-height: 100vh;
    transform:translate(0,0) translate3d(0,0,0);
}
.push {
    transform:translate($menu-width,0) translate3d(0,0,0);
	transition:.5s ease all;
//	position: fixed;
}
